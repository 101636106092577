@import '~leaflet/dist/leaflet.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-form-builder .react-form-builder-toolbar {
    width: auto;
    margin-top: 0;
}

.dmn-decision-table-container .cell {
    overflow: hidden;
    text-overflow: ellipsis;
}
.leaflet-control-locate a .leaflet-control-locate-location-arrow {
  background-image: url('./static/locate-arrow.svg') !important;
}
.leaflet-control-locate a .leaflet-control-locate-spinner {
  background-image: url('./static/locate-spinner.svg') !important;
}
.leaflet-control-locate.active a .leaflet-control-locate-location-arrow {
  background-image: url('./static/locate-arrow-active.svg') !important;
}
.leaflet-control-locate.following a .leaflet-control-locate-location-arrow {
  background-image: url('./static/locate-arrow-following.svg') !important;
}
